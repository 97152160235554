
import {
    InjectReactive as FromParentReactive,
    Inject as FromParent,
    Component,
    Mixins,
} from 'vue-property-decorator';

import HistoryTableMixin from '../../mixins/history-table.mixin';

@Component
export default class PromotionsHistoryTable extends Mixins(HistoryTableMixin) {
    @FromParentReactive('filters')
    private filters!: {
        provider?: string;
        program?: string;
    };

    @FromParentReactive('isDealProgram')
    public isDealProgram!: boolean;

    @FromParentReactive('mainHotelId')
    private mainHotelId!: number;

    @FromParentReactive('daysAgo')
    private daysAgo!: number;

    @FromParentReactive('lastScanDay')
    private lastScanDay!: number;

    @FromParentReactive('hiddenGraphs')
    public hiddenGraphs!: { [hotelId: string]: false };

    @FromParentReactive('isLoading')
    public isLoading!: boolean;

    @FromParent('toggleGraph')
    public toggleGraph!: (hotelId: number) => void;

    public get tableData() {
        if (this.isLoading) {
            return Array
                .from({ length: 5 })
                .map((_, index) => ({
                    id: index,
                    name: '___________',
                    program: '__________',
                    programStatus: '___',
                    percent: '___',
                    rank: 0,
                    color: '',
                    isMain: false,
                }));
        }

        return this.getTableData(this.lastScanDay, this.daysAgo, this.mainHotelId, this.filters);
    }
}

