var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('CustomTooltip',_vm._b({attrs:{"tooltip-class":[
        'promotion-history-tooltip',
        _vm.isDealProgram && 'promotion-history-tooltip--deal',
    ].join(' '),"verticalOffset":_vm.verticalOffset},scopedSlots:_vm._u([{key:"headline",fn:function(){return [_c('div',{staticClass:"promotion-history-tooltip__headline"},[_vm._v(" "+_vm._s(_vm.date)+" ")])]},proxy:true}])},'CustomTooltip',_vm.attrs,false),[_c('table',{staticClass:"promotion-history-tooltip__table"},[_c('thead',[_c('th'),_c('th',{domProps:{"textContent":_vm._s(_vm.$tc('titles.hotelName'))}}),(_vm.isDealProgram)?_c('th',{domProps:{"textContent":_vm._s(_vm.$tc('titles.rank'))}}):_vm._e(),_c('th',{domProps:{"textContent":_vm._s(_vm.$tc('titles.program'))}}),_c('th',{domProps:{"textContent":_vm._s(_vm.$tc('status'))}}),(_vm.isDealProgram)?_c('th',{domProps:{"textContent":_vm._s(_vm.$tc('percent'))}}):_vm._e()]),_c('tbody',_vm._l((_vm.tableData),function(hotel){return _c('tr',{key:hotel.id,class:{
                    'promotion-history-tooltip__row': true,
                    'promotion-history-tooltip__row--main': hotel.isMain,
                }},[_c('td',[_c('div',{class:{
                            'graph-legend': true,
                            'graph-legend--hidden': _vm.hiddenGraphs[hotel.id],
                        },style:({ '--indicator-color': hotel.color }),on:{"click":function($event){return _vm.toggleGraph(hotel.id)}}})]),_c('td',{domProps:{"textContent":_vm._s(hotel.name)}}),(_vm.isDealProgram)?_c('td',{domProps:{"textContent":_vm._s(hotel.rank)}}):_vm._e(),_c('td',{domProps:{"textContent":_vm._s(hotel.program)}}),_c('td',[_c('i',{class:`icon-${hotel.programStatus ? 'v' : 'x'}-mark`})]),(_vm.isDealProgram)?_c('td',{domProps:{"textContent":_vm._s(hotel.percent + '%')}}):_vm._e()])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }